import * as React from 'react';
import { Link } from '@mui/material';

import GitHub from '../images/skills/Github.png';
import LinkedIn from '../images/contact/LinkedIn.png';
import EmailMe from '../images/contact/EmailMe.png';
import { styled } from '@mui/system';

const CodeContainer = styled('div')({
  fontFamily: "'Fira Code', 'Consolas', 'Courier New', monospace",
  color: '#ffffff',
  marginBottom: '20px',
  paddingLeft: '20px',
  paddingTop: "20px"
});

const ImportKeyword = styled('span')({
  color: '#c586c0', 
});

const ImportPackage = styled('span')({
  color: '#ce9178', 
});

const Brackets = styled('span')({
  color: '#ffd602', 
});

const Star = styled('span')({
  color: '#559bd5', 
});

const ComponentName = styled('span')({
  color: '#8bc0de', 
});

const ContactContainer = styled("div")({
  backgroundColor: '#000000', 
  fontFamily: "'Fira Code', 'Consolas', 'Courier New', monospace",
  lineHeight: '1.6',
  minHeight: '100vh',
  margin: '0 auto',   
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: 0,
  overflowX: 'hidden',  
  wordWrap: 'break-word',
});

const Tag = styled('span')({
  color: '#569cd6',
});

const Keyword = styled('span')({
  color: '#c586c0',
});

const FunctionKeyword = styled('span')({
  color: '#569CD6',  
});

const FunctionName = styled('span')({
  color: '#dcdcaa',
});

const FragmentTag = styled('span')({
  color: '#4EC9B0',
});

const LinkTag = styled('span')({
  color: '#4EC9B0', 
});

const Attribute = styled('span')({
  color: '#9cdcfe', 
});

const String = styled('span')({
  color: '#ce9178', 
});

const StyledLink = styled(Link)({
  color: 'white', 
  textDecoration: 'none',
  wordBreak: 'break-word',  
  overflowWrap: 'break-word',  
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Indent = styled('div')({
  paddingLeft: '40px',  
  wordBreak: 'break-word',  
});

const Paragraph = styled('p')({
  margin: "0px"
});

const ContactImage = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ContactLinks = styled('div')({
  display: 'flex',
  padding: '20px',
});

const SmallIcon = styled('img')({
  width: '35px', 
  height: '35px',
  filter: 'invert(100%)'
});

const StickyMenuContainer = styled('div')({
  display: 'flex',
  justifyContent: "space-between",
  alignItems: "center",
  position: 'sticky',
  top: 0, 
  backgroundColor: '#3a3a3a', 
  borderBottom: '1px solid #ccc', 
  padding: '20px', 
  zIndex: 1000, 
  color: 'white', 
  fontSize: '18px', 
});

const MainContainer = styled('div')({
  backgroundColor: "black", 
  color: "white", 
  padding: '0', 
  maxWidth: '100%',  
});

export default function Portfolio() {
  return (
    <ContactContainer>
      <StickyMenuContainer>
        <div>
          Joe Rose. Front End Software Developer.
        </div>
        <div>
          <ContactLinks>
            <a href="https://www.linkedin.com/in/jrose117/" target="_blank" rel="noreferrer" style={{padding: "0px 15px"}}>
              <ContactImage>
                <SmallIcon src={LinkedIn} alt="LinkedIn" />
              </ContactImage>
            </a>
            <a href="https://github.com/JRose117/" target="_blank" rel="noreferrer" style={{padding: "0px 15px"}}>
              <ContactImage>
                <SmallIcon src={GitHub} alt="GitHub" />
              </ContactImage>
            </a>
            <a href="mailto:joerose3546@gmail.com?subject=Hi Joe!" target="_blank" rel="noreferrer" style={{padding: "0px 15px"}}>
              <ContactImage>
                <SmallIcon src={EmailMe} alt="Email Me" />
              </ContactImage>
            </a>
          </ContactLinks>
        </div>
      </StickyMenuContainer>
      <CodeContainer>
        <ImportKeyword>import</ImportKeyword> <Star>*</Star> <ImportKeyword>as</ImportKeyword> <ComponentName>React</ComponentName> <ImportKeyword>from</ImportKeyword> <ImportPackage>'react'</ImportPackage><span>;</span><br/>
        <ImportKeyword>import</ImportKeyword> <Brackets>{'{ '}</Brackets><ComponentName>Link</ComponentName><Brackets>{' }'}</Brackets> <ImportKeyword>from</ImportKeyword> <ImportPackage>'@mui/material'</ImportPackage><span>;</span>
      </CodeContainer>
      <div>
        <Keyword style={{paddingLeft: "20px"}}>export default</Keyword> 
        <FunctionKeyword> function</FunctionKeyword>
        <FunctionName> Portfolio</FunctionName>
        <span style={{ color: 'white' }}>
          ()
        </span>
        <span style={{ color: 'white' }}>
          {' {'}
        </span>
        <br />
        <Indent>
          <Keyword>return</Keyword> 
          <span style={{color: 'white'}}>(</span>
          <Indent>
            <FragmentTag>&lt;React.Fragment&gt;</FragmentTag>
            <Indent>
              <MainContainer>
                <div>
                  <Tag>&lt;strong&gt;</Tag>
                  <Indent>
                    <strong>Employment</strong>
                  </Indent>
                  <Tag>&lt;/strong&gt;</Tag>
                </div>

                <div>
                  <div>
                      <LinkTag>&lt;Link </LinkTag>
                      <Attribute>href=</Attribute>
                      <String>"https://www.cathree.com/about-us"</String> <Attribute>target=</Attribute>
                      <String>"_blank"</String> <Attribute>rel=</Attribute>
                      <String>"noreferrer"</String>
                      <LinkTag>&gt;</LinkTag>
                      <Indent>
                        <StyledLink href="https://www.cathree.com/about-us" target="_blank" rel="noreferrer">
                          Front End Software Developer. 02/2022 - Present
                        </StyledLink>
                      </Indent>
                      <LinkTag>&lt;/Link&gt;</LinkTag>
                  </div>
                </div>
                <div>
                  <div>
                    <Tag>&lt;p&gt;</Tag>
                    <Indent>
                      <Paragraph>Maths Teacher. 2015 - 2022</Paragraph>
                    </Indent>
                    <Tag>&lt;/p&gt;</Tag>
                  </div>
                </div>
                <div>
                  <div>
                    <Tag>&lt;p&gt;</Tag>
                    <Indent>
                      <Paragraph>Co-Founder. Ed Tech Data Start-Up. Akiva.uk. 2017-18</Paragraph>
                    </Indent>
                    <Tag>&lt;/p&gt;</Tag>
                  </div>
                </div>

                <Tag>&lt;strong&gt;</Tag>
                <strong>
                  <Indent>
                    Education 
                  </Indent>
                </strong>
                <Tag>&lt;/strong&gt;</Tag>
                <div>
                  <div>
                    <Tag>&lt;p&gt;</Tag>
                    <Indent>
                      <Paragraph>General Assembly Software Engineering Bootcamp. 2022</Paragraph>
                    </Indent>
                    <Tag>&lt;/p&gt;</Tag>
                  </div>
                </div>

                <div>
                  <div>
                    <Tag>&lt;p&gt;</Tag>
                    <Indent>
                      <Paragraph>Institute of Education, University College London, Teach First Programme. Graded Outstanding. PGCE. 2015-16</Paragraph>
                    </Indent>
                    <Tag>&lt;/p&gt;</Tag>
                  </div>
                </div>

                <div>
                  <div>
                    <Tag>&lt;p&gt;</Tag>
                    <Indent>
                      <Paragraph>Canterbury Christ Church University Maths SKE Course. 2015</Paragraph>
                    </Indent>
                    <Tag>&lt;/p&gt;</Tag>
                  </div>
                </div>

                <div>
                  <div>
                    <Tag>&lt;p&gt;</Tag>
                    <Indent>
                      <Paragraph>University of Birmingham, 2:1 in BA (Sociology) (69 Overall). 2012-15</Paragraph>
                    </Indent>
                    <Tag>&lt;/p&gt;</Tag>
                  </div>
                </div>
              </MainContainer>
            </Indent>
            <FragmentTag>&lt;/React.Fragment&gt;</FragmentTag>
          </Indent>
          <span style={{color: 'white'}}>)</span>
        </Indent>
        <span style={{color: 'white', padding: "0px 0px 20px 20px"}}>{'}'}</span>
      </div>
    </ContactContainer>
  );
}
